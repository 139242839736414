<template>
  <div id="media-list">
    <v-card>
      <v-card-title>メディア</v-card-title>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-btn
          color="primary"
          class="mb-4 me-3"
          @click="openFilePicker"
        >
          <span>アップロード</span>
        </v-btn>
        <MediaCreate
          :parent-id="targetFolderId"
          :directory="tgtDirectory"
          @reloadMedia="refreshMedias"
        ></MediaCreate>
        <v-btn
          color="primary"
          class="mb-4 me-3"
          @click="refreshMedias"
        >
          <span>リフレシュ</span>
        </v-btn>
        <v-autocomplete
          class="mb-4 me-3"
          placeholder="Filter"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
        <v-autocomplete
          class="mb-4 me-3"
          placeholder="View in"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pt-2 pb-2">
        <v-row>
          <v-col cols="12" md="10" class="d-flex align-center">
            <!-- <span>All media</span> -->
            <v-breadcrumbs :items="mediaBreadcrumbs">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  style="cursor:pointer;color: #1867c0;"
                  @click="reLoadBreadcrumbs(item)"
                >
                  {{ item.text }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-col>
          <v-col
            align-self="center"
            style="text-align: center;"
            cols="12"
            md="2"
          >
            <v-btn-toggle>
              <v-btn depressed>
                <v-icon>{{ icons.mdiGrid }}</v-icon>
              </v-btn>

              <v-btn depressed>
                <v-icon>{{ icons.mdiFormatAlignLeft }}</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>

      <v-row class="ma-0">
        <v-col cols="12" md="10" class="pa-0" @click="clearSelected">
          <v-row class="ma-0 pr-3 pb-3">
            <v-col
              v-for="(item, i) in medias"
              :key="'folder' + i"
              class="pr-0 pb-0"
              cols="6"
              md="2"
            >
              <v-card
                v-if="item.type === 'folder'"
                class="d-flex flex-column align-content-space-between"
                :style="item.active? 'border-color: #9155FD;' : 'border-color: #CFD8DC;'"
                max-width="400"
                height="150px"
                flat
                tile
                outlined
                @dblclick="updateBreadcrumbs(item)"
                @click.stop="addSelectedList(item, i)"
                @contextmenu.prevent="openMenu($event,item, i)"
              >
                <v-card-actions style="height:110px;" class="pa-3">
                  <v-img
                    src="@/assets/images/Folder.png"
                    height="100%"
                    contain
                  >
                  </v-img>
                </v-card-actions>
                <v-card-actions class="d-flex justify-center pa-2" :style="`${item.active ? 'background-color:#9155FD;' : 'background-color:#C0C0C0;'}flex:1;`">
                  <span :class="`${item.active ? 'activeName' : ''} subName`" style="font-size: 14px;" :title="item.name">{{ item.name }}</span>
                </v-card-actions>
              </v-card>

              <v-card
                v-else
                class="d-flex flex-column align-content-space-between"
                color="#fafafa"
                :style="item.active? 'border-color: #9155FD;' : 'border-color: #CFD8DC;'"
                :input-value="item.active"
                max-width="400"
                height="150px"
                flat
                tile
                outlined
                @click.stop="addSelectedList(item, i)"
                @contextmenu.prevent="openMenu($event,item, i)"
              >
                <v-card-actions style="height:110px;" class="pa-0">
                  <v-img
                    :src="item.full_url"
                    height="100%"
                    contain
                  >
                  </v-img>
                </v-card-actions>
                <v-card-actions class="d-flex justify-center pb-2 pt-2" :style="`${item.active ? 'background-color:#9155FD;' : 'background-color:#ddd;'}flex:1;`">
                  <span :class="`${item.active ? 'activeName' : ''} subName`" style="font-size: 14px;" :title="item.name">{{ item.name }}</span>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-divider
          vertical
        ></v-divider>
        <v-col cols="12" md="2" class="pa-0">
          <v-container class="pa-0 selectedTarget">
            <v-row class="pa-2 d-flex justify-center ma-0">
              <v-col cols="12" class="pa-0">
                <v-img
                  v-if="selectedTarget.type === 'folder'"
                  src="@/assets/images/Folder.png"
                  height="100%"
                  contain
                >
                </v-img>
                <v-img
                  v-else
                  :src="selectedTarget.full_url ? selectedTarget.full_url : require('@/assets/images/noimage.jpg')"
                  width="100%"
                  min-height="110"
                  contain
                >
                </v-img>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row v-if="Object.keys(selectedTarget).length === 0" class="ma-0">
              <v-col cols="12">
                <span style="font-size: 14px;font-weight:bold;">Nothing is selected</span>
              </v-col>
            </v-row>
            <v-row v-else class="ma-0">
              <v-col cols="12" class="pa-2 pb-0 pt-3">
                <span style="font-size: 14px;font-weight:bold;">Name</span>
              </v-col>
              <v-col cols="12" class="py-0">
                <span style="font-size: 14px;">{{ selectedTarget.name }}</span>
              </v-col>
              <v-col v-if="selectedTarget.type === 'file'" cols="12" class="pa-2 pb-0 pt-3">
                <span style="font-size: 14px;font-weight:bold;">Full URL</span>
              </v-col>
              <v-col v-if="selectedTarget.type === 'file'" cols="12" class="pt-0">
                <span style="font-size: 14px;">{{ selectedTarget.full_url }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-card>
    <ConfirmDelete
      :dialog="confirmDialog"
      :delete-type="selectedType"
      @closeForm="closeConfirmDialog"
      @deleteItem="deleteTargets"
    ></ConfirmDelete>
    <MediaRename
      :dialog="renameDialog"
      :target-files="selectedList"
      @renameMedia="renameTargets"
      @closeForm="closeRenameDialog"
    ></MediaRename>
    <media-edit-menu
      :visible="visible"
      :left="left"
      :top="top"
      @openConfirmDelete="openConfirmDelete"
      @openRenameDialog="openRenameDialog"
    ></media-edit-menu>
  </div>
</template>

<script>
import {
  mdiPencil,
  mdiDelete,
  mdiExportVariant,
  mdiGrid,
  mdiFormatAlignLeft,
  mdiFolder,
} from '@mdi/js'
import {
  mapState, mapMutations, mapActions,
} from 'vuex'
import MediaCreate from './MediaCreate.vue'
import MediaEditMenu from './MediaEditMenu.vue'
import ConfirmDelete from '@/views/setting/Media/ConfirmDelete.vue'
import MediaRename from '@/views/setting/Media/MediaRename.vue'

export default {
  components: {
    MediaCreate,
    MediaEditMenu,
    ConfirmDelete,
    MediaRename,
  },

  data: () => ({
    isCtrl: false,
    isShift: false,
    confirmDialog: false,
    renameDialog: false,
    visible: false,
    top: 0,
    left: 0,
    icons: {
      mdiPencil,
      mdiDelete,
      mdiExportVariant,
      mdiGrid,
      mdiFormatAlignLeft,
      mdiFolder,
    },
    searchQuery: '',
    medias: [
    ],
    arrFileHandle: [],
    selectedTarget: {
    },
    selectedList: [],
    selectedListIndex: [],
    selectedIndex: null,
    selectedType: 0,
    tgtDirectory: '',
  }),

  computed: {
    ...mapState('mediaStore', ['mediaList', 'mediaBreadcrumbs', 'targetFolderId']),
  },

  watch: {
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    },
    selectedListIndex: {
      handler(newValue) {
        this.selectedList = []
        this.selectedType = 0
        const selectedTypes = []
        this.clearListActive()
        newValue.forEach(n => {
          const obj = {
            ...this.medias[n],
          }
          obj.active = true
          this.$set(this.medias, n, obj)
          this.selectedList.push(obj)
          if (!selectedTypes.includes(obj.type)) {
            selectedTypes.push(obj.type)
            if (obj.type === 'folder') {
              this.selectedType += 1
            }
            if (obj.type === 'file') {
              this.selectedType += 2
            }
          }
        })
      },
      deep: true,
    },

    mediaBreadcrumbs() {
      this.buildDirectory()
    },
  },

  created() {
    this.loadMedias(this.targetFolderId)
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown)
    window.addEventListener('keyup', this.handleKeyUp)
  },
  destroyed() {
    window.removeEventListener('keydown', this.handleKeyDown)
    window.removeEventListener('keyup', this.handleKeyUp)
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapMutations('mediaStore', ['updateMediaBreadcrumbs', 'reLoadMediaBreadcrumbs']),
    ...mapActions('mediaStore', ['loadMediaList', 'uploadImg', 'deleteMedia', 'renameMedia']),
    handleKeyDown(event) {
      if (event.key === 'Control') this.isCtrl = true
      if (event.key === 'Shift') this.isShift = true
      console.log('handleKeyDown', this.isCtrl, this.isShift)
    },
    handleKeyUp(event) {
      if (event.key === 'Control') this.isCtrl = false
      if (event.key === 'Shift') this.isShift = false
      console.log('handleKeyUp', this.isCtrl, this.isShift)
    },
    openMenu(e, item, i) {
      console.log('openMenu', e, item, i, this.selectedListIndex)
      if (this.selectedListIndex.indexOf(i) === -1) {
        this.addSelectedList(item, i)
      }

      const navMenu = document.getElementsByClassName('app-navigation-menu')[0].style.transform
      if (navMenu === 'translateX(0%)') {
        this.left = e.pageX - 260
      } else {
        this.left = e.pageX
      }
      this.top = e.pageY - 66

      this.visible = true
    },

    closeMenu() {
      this.visible = false

      // this.selectedListIndex = []
    },
    openConfirmDelete() {
      this.confirmDialog = true
    },
    openRenameDialog() {
      this.renameDialog = true
    },

    renameTargets(renameMeidaList) {
      this.setOverlayStatus(true)

      console.log('updateList', renameMeidaList)
      this.renameMedia({
        updateList: renameMeidaList,
      }).then(() => {
        this.refreshMedias()
      }).catch(e => {
        this.setOverlayStatus(false)
        console.log('renameTargets error', e)
      })
    },

    closeConfirmDialog() {
      this.confirmDialog = false
    },
    closeRenameDialog() {
      this.renameDialog = false
    },

    deleteTargets() {
      this.setOverlayStatus(true)
      const deleteMedias = [...this.selectedList]
      this.selectedList.forEach((sl, i) => {
        if (sl.type === 'folder') {
          deleteMedias[i].path = `${this.tgtDirectory}/${sl.slug}`
        } else if (sl.type === 'file') {
          deleteMedias[i].path = `${this.tgtDirectory}/${sl.basename}`
        }
      })
      console.log('deleteTargets', this.selectedList, deleteMedias)
      this.deleteMedia({
        deleteList: deleteMedias,
      }).then(() => {
        this.refreshMedias()
      }).catch(e => {
        this.setOverlayStatus(false)
        console.log('deleteTargets error', e)
      })
    },

    loadMedias(folderId) {
      this.setOverlayStatus(true)
      this.loadMediaList({
        folder_id: folderId,
      }).then(() => {
        const mediaFolders = this.mediaList.folders.map(mf => {
          const obj = mf
          obj.type = 'folder'
          obj.active = false

          return obj
        })
        const mediaFiles = this.mediaList.files.map(mf => {
          const obj = mf
          obj.type = 'file'
          obj.active = false

          return obj
        })
        this.medias = [...mediaFolders, ...mediaFiles]
        console.log('loadMedias', this.medias)
      }).finally(() => {
        this.setOverlayStatus(false)
        this.selectedListIndex = []
        this.selectedTarget = {
        }
      })
    },
    clearListActive() {
      this.medias.forEach((m, i) => {
        const obj = m
        obj.active = false
        this.$set(this.medias, i, obj)
      })
    },
    clearSelected() {
      this.selectedListIndex = []
      this.clearListActive()
    },
    addSelectedList(item, i) {
      if (this.isShift) {
        if (this.selectedListIndex.indexOf(i) === -1) {
          this.selectedListIndex.push(i)
        }
        if (this.selectedListIndex.length > 1) {
          const min = Math.min(this.selectedIndex, i)
          const max = Math.max(this.selectedIndex, i)
          console.log('media', this.selectedListIndex, min, max)
          this.selectedListIndex = []
          this.medias.forEach((m, mi) => {
            if (mi >= min && mi <= max) {
              this.selectedListIndex.push(mi)
            }
          })
        }
      } else if (this.isCtrl) {
        if (this.selectedListIndex.indexOf(i) === -1) {
          this.selectedListIndex.push(i)
        } else {
          this.selectedListIndex.splice(this.selectedListIndex.indexOf(i), 1)
        }
      } else {
        this.visible = false
        this.selectedListIndex = []
        this.selectedListIndex.push(i)
      }
      if (this.selectedListIndex.length <= 1) {
        this.selectedIndex = i
      }
      this.selectedTarget = item
    },

    buildDirectory() {
      const Folders = []
      this.mediaBreadcrumbs.forEach((m, i) => {
        if (i !== 0) {
          Folders.push(m.text)
        }
      })
      this.tgtDirectory = Folders.join('/')
    },

    updateBreadcrumbs(folder) {
      this.updateMediaBreadcrumbs(folder)
      this.loadMedias(folder.id)
    },

    reLoadBreadcrumbs(folder) {
      this.reLoadMediaBreadcrumbs(folder)
      this.loadMedias(folder.folderId)
    },

    async uploadImages(arrFile) {
      this.setOverlayStatus(true)
      const Folders = []
      this.mediaBreadcrumbs.forEach((m, i) => {
        if (i !== 0) {
          Folders.push(m.text)
        }
      })
      this.tgtDirectory = Folders.join('/')
      console.log('uploadImages', Folders, this.tgtDirectory, this.targetFolderId)

      Promise.all(arrFile.map(async af => {
        const result = this.uploadImg({
          img: af, tgtDirectory: this.tgtDirectory, tgtFolderId: this.targetFolderId,
        }).catch(error => {
          console.log('upload error', error)
        })

        return result
      })).then(() => {
        this.refreshMedias()
      }).catch(error => {
        this.setOverlayStatus(false)
        console.log('uploadImg error', error)
      })
    },

    refreshMedias() {
      this.loadMedias(this.targetFolderId)
    },

    async openFilePicker() {
      this.arrFileHandle = await window.showOpenFilePicker({
        types: [{
          description: 'Images',
          accept: {
            'image/*': ['.png', '.gif', '.jpeg', '.jpg', '.webp'],
          },
        }],
        multiple: true,
      })
      const arrFile = await Promise.all(this.arrFileHandle.map(async f => {
        const file = await f.getFile()

        return file
      }))

      this.uploadImages(arrFile)
    },
  },
}
</script>

<style scoped>
.subName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -moz-user-select:none;
  -webkit-user-select:none;
  -ms-user-select:none;
  -khtml-user-select:none;
  user-select:none;
}
.activeName{
  color: #fff;
  -moz-user-select:none;
  -webkit-user-select:none;
  -ms-user-select:none;
  -khtml-user-select:none;
  user-select:none;
}
.border {
  border: 2px dashed orange;
}
.selectedTarget{
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
}
.contextmenu {
  margin: 0;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
}

.contextmenu li {
  margin: 0;
  padding: 7px 16px;
  cursor: pointer;
}

.contextmenu li:hover {
  background: #eee;
}
</style>
